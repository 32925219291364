<script setup>
import { ref, watch } from "vue";
import c3api from "../../c3api";
import { onMounted } from "vue";
import { useUserStore } from "../../stores/user";
import { useToast } from "vue-toastification";
import { setPageTitle } from "../../utils/pageTitle";
import { useRouter, definePage } from "vue-router/auto";

definePage({
  meta: {
    requiresAuth: false,
  },
});

const email = ref(null);
const userStore = useUserStore();
const router = useRouter();
const loading = ref(false);
const toast = useToast();
setPageTitle("Reset Password");

const submitForm = async () => {
  loading.value = true;
  c3api
    .post("/auth/reset_password", {
      email: email.value,
      base_confirmation_url: import.meta.env.VITE_PASSWORD_RESET_CONFIRMATION_URL,
    })
    .then(async (response) => {
      toast.success("Success, check your e-mail for the reset code.");
      router.push("/");
    })
    .catch((error) => {
      console.error(error.response);
      toast.error(error.response.data.data.join(". "));
    })
    .finally(() => {
      loading.value = false;
    });
};

onMounted(() => {
  if (localStorage.getItem("token") != null) {
    userStore.fetchUser();
  }
});
</script>

<template>
  <v-container
    class="d-flex flex-wrap justify-center align-center"
    style="height: 35vh; max-width: 540px"
  >
    <div class="d-flex flex-column justify-center align-center" v-if="loading">
      <v-progress-circular
        :width="10"
        :size="100"
        color="indigo-darken-1"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-card v-if="!userStore.user && !loading" class="w-100 pa-2">
      <v-card-item>
        <v-card-title class="pb-1">Reset Password</v-card-title>
      </v-card-item>
      <v-card-text class="mt-4">
        <div>
          <v-text-field type="email" label="Email" v-model="email" />
        </div>
        <div class="d-flex justify-end mt-2">
          <v-btn @click.prevent="submitForm" type="submit" color="primary"
            >Request reset code</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>
